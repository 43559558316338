export const randomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

export const btnFormat = (res) => {
    let list = [];
    res.forEach(value => {
        if (value.score !== null) {
            value.is_pw = true;
        } else {
            value.is_pw = false;
        }

        list.push(value)
    })
    return list;
}
